<template>
<div>
  <b-row class="match-height">

    <b-col
      lg="8"
      md="6"
    >
      <erp-crm-pay-company />
    </b-col>

    <b-col
      lg="4"
      md="6"
    >
      <card-advance-pay-amount />
    </b-col>
    
  </b-row>
  <free-trial />
</div>
</template>

<script>
import { BRow, BCol } from 'bootstrap-vue'

import CardAdvanceProfile from './CardAdvanceProfile.vue'
import ErpCrmPayCompany from './ErpCrmPayCompany.vue'
import CardAdvancePayAmount from './ErpCrmPayCard.vue'



export default {
  components: {
    BRow,
    BCol,

    CardAdvanceProfile,
    ErpCrmPayCompany,
    CardAdvancePayAmount,

  },
}
</script>
