<template>
  <b-card
    no-body
    class="card-payment"
  >
    <b-card-header>
      <b-card-title>Organization Details</b-card-title>
    </b-card-header>
    <b-card-body>
      <!-- form -->
      <b-form>
        <b-row>
          <b-col cols="12">
            <b-form-group
              label="Organization / Name"
              label-for="org-name"
              class="mb-2"
            >
              <cleave
                id="org-name"
                v-model="cardNumber"
                class="form-control"
                placeholder="Organization Name"
              />
            </b-form-group>
          </b-col>
          <b-col sm="6">
            <b-form-group
              label="Username"
              label-for="expiry"
              class="mb-2"
            >
              <b-form-input
                id="expiry"
                placeholder="username"
              />
            </b-form-group>
          </b-col>
          <b-col sm="6">
            <b-form-group
              label="Password"
              label-for="cvv-cvc"
              class="mb-2"
            >
              <b-form-input
                id="cvv-cvc "
                placeholder="StrOngP4$$W0rd"
              />
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group
              label="Email"
              label-for="name"
              class="mb-2"
            >
              <b-form-input
                id="name"
                placeholder="curtis.stone@cloud-inspire.io"
              />
            </b-form-group>
          </b-col>
        </b-row>
      </b-form>
      <!--/ form -->
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard, BCardHeader, BCardTitle, BCardBody, BRow, BCol, BFormGroup, BFormInput, BButton, BForm,
} from 'bootstrap-vue'
import Cleave from 'vue-cleave-component'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BCard,
    BCardHeader,
    BCardTitle,
    BCardBody,
    BRow,
    BCol,
    BForm,
    BFormGroup,
    BFormInput,
    Cleave,
    BButton,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      cardNumber: '',
      options: {
        creditCard: {
          creditCard: true,
        },
      },
    }
  },
}
</script>
